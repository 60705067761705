main {
    h2 {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 1.5em;
    }

    hr {
        height: 3px !important;
        width: 100%;
        background-color: #030028;
    }
}