.intro {
    padding-bottom: 0 !important;
    
    &__job {
        font-size: 1.2em;
    }

    &__blurb {
        max-width: 650px;
    }
}