.skills {
    h3 {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 1.2em;
    }

    &__wrapper {
        display: grid;
        grid-template-columns: repeat( 1, minmax( 0, 1fr ) );
        grid-gap: 30px;

        .p-4,
        .p-md-5 {
            padding: 0 !important;
        }        

        @media (min-width: 576px) {
            grid-template-columns: repeat( 4, minmax( 0, 1fr ) );
        }
    }
}
