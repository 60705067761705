.sidebar {
    background-color: #030028;
    color: white;
    min-height: 100vh;
    position: relative;

    .profile-image {
        border-radius: 50%;
        width: 200px;
        margin: 0 auto;
        display: block;
        filter: grayscale(100%);
        max-width: 100%;
    }

    h2 {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 1.5em;
    }

    hr {
        height: 3px;
        width: 100%;
        background-color: white;
    }

    ul {
        list-style: none;
        padding: 0;

        li {
            margin-bottom: 15px;

            strong {
                display: block;
            }
        }
    }

    &__contact-icons {
        margin: 20px 0 0;
        
        div {
            margin: 0 10px;
        }
    }

    &__contact-icon {
        width: 20px;
        height: 20px;
        display: block;

        svg {
            width: 100%;
            height: 100%;

            path {
                fill: white;
            }
        }
    }

    &__phone-icon {
        svg {
            path {
                &:first-of-type {
                    fill: none;
                }
            }
        }
    }

    &__contents {
        margin: 30px 0 0;

        ul {
            li {
                a {
                    text-decoration: none;
                    color: white;
                }

                ul {
                    li {
                        margin: 0 0 5px 20px;
                    }
                }
            }
        }
    }
}