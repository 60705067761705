.social {
    position: absolute;
    bottom: 40px;
    width: calc(100% - 6rem);
    
    &__wrapper {
        display: flex;
        justify-content: center;
        
        a {
            display: inline-block;
            width: 25px;
            height: 25px;
            margin: 0 10px;

            svg {
                width: 100%;
                height: 100%;
            }

            &.github-icon {
                svg {
                    path {
                        fill: white;
                    }
                }
            }
        }
    }
}