.contact {
    &__contact-icon {
        margin: 0 1rem 0 0;
        
        svg {
            path {
                fill: #030028;
            }
        }

        &.phone-icon {
            svg {
                path:first-of-type {
                    fill: none;
                }
            }
        }
    }

    &__details {
        div a {
            display: flex;
        }
    }
}