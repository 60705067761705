.employment {
    h3 {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 1.4em;
    }

    hr {
        background-color: #030028;
        opacity: 1;
    }

    &__responsibilites {
        display: grid;
        grid-template-columns: repeat( 1, minmax( 0, 1fr ) );
        grid-gap: 30px;

        @media (min-width: 576px) {
            grid-template-columns: repeat( 3, minmax( 0, 1fr ) );
        }
    }

    &__meta {
        display: grid;
        grid-template-columns: repeat( 1, minmax( 0, 1fr ) );
        grid-gap: 30px;

        @media (min-width: 576px) {
            grid-template-columns: repeat( 2, minmax( 0, 1fr ) );
        }

        p {
            margin: 0;
            font-weight: bold;
            text-transform: uppercase;

            &:last-of-type {
                text-align: center;

                @media (min-width: 576px) {
                    text-align: right;
                }
            }
        }
    }

    &__technologies {
        @media (min-width: 576px) {
            grid-template-columns: minmax( 0, 1fr ) minmax( 0, 2fr );
        }

        div {
            display: flex;

            &.right {
                text-align: right;
                justify-content: flex-end;
            }
            
            span {
                margin: 0 8px;
            }
        }

        .tech {
            text-align: left;
            display: block;

            @media (min-width: 576px) {
                display: inline;
            }

            @media (min-width: 768px) {
                display: block;
                text-align: right;
            }

            @media (min-width: 1200px) {
                text-align: left;
            }
        }

        .dot {
            display: none;

            @media (min-width: 576px) {
                display: inline;
            }

            @media (min-width: 768px) {
                display: none;
            }

            @media (min-width: 1200px) {
                display: inline;
            }
        }
    }

    &__employer {
        border: 3px solid #030028;
        text-align: center;

        @media (min-width: 576px) {
            text-align: left;
        }
    }

    .carousel-status,
    .control-dots,
    .carousel {
        display: none;

        &.carousel-slider {
            display: block;
        }
    }

    &__controls {
        margin: 0 0 20px 0;
        display: flex;
        justify-content: space-between;

        .arrow {
            color: #030028;
            text-decoration: none;
            cursor: pointer;
            user-select: none;

            &.disabled {
                visibility: hidden;
            }
            
            svg {
                width: 20px;
                height: 15px;
            }

            &.next {
                svg {
                    transform: rotate( 180deg );
                }
            }
        }
    }
}
